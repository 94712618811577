//
// Base styles
//

.accordion {
    // scss-docs-start accordion-css-vars
    --bs-accordion-color: #212529;
    --bs-accordion-bg: #fff;
    --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
    --bs-accordion-border-color: var(--bs-border-color);
    --bs-accordion-border-width: 1px;
    --bs-accordion-border-radius: 0.375rem;
    --bs-accordion-inner-border-radius: calc(0.375rem - 1px);
    --bs-accordion-btn-padding-x: 1.25rem;
    --bs-accordion-btn-padding-y: 1rem;
    --bs-accordion-btn-color: #000;
    --bs-accordion-btn-bg:  var(--bs-accordion-bg);
    --bs-accordion-btn-icon: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e);
    --bs-accordion-btn-icon-width: 1.25rem;
    --bs-accordion-btn-icon-transform: rotate(-180deg);
    --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
    --bs-accordion-btn-active-icon: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%230c63e4%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e);
    --bs-accordion-btn-focus-border-color: #fff;
    --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(255,255,255, 0.25);
    --bs-accordion-body-padding-x: 1.25rem;
    --bs-accordion-body-padding-y: 1rem;
    --bs-accordion-active-color: #000;
    --bs-accordion-active-bg: #fff;
    // scss-docs-end accordion-css-vars
  }
  
  .accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
    font-size: $font-size-base;
    color: var(--bs-accordion-btn-color);
    text-align: left; // Reset button style
    background-color: var(--bs-accordion-btn-bg);
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: --bs-accordion-transition;
  
    &:not(.collapsed) {
      color: var(--bs-accordion-active-color);
      background-color: var(--bs-accordion-active-bg);
      box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color); // stylelint-disable-line function-disallowed-list
  
      &::after {
        background-image: var(--bs-accordion-btn-active-icon);
        transform: var(--bs-accordion-btn-icon-transform);
      }
    }
  
    // Accordion icon
    &::after {
      flex-shrink: 0;
      width: var(--bs-accordion-btn-icon-width);
      height: var(--bs-accordion-btn-icon-width);
      margin-left: auto;
      content: "";
      background-image: var(--bs-accordion-btn-icon);
      background-repeat: no-repeat;
      background-size: var(--bs-accordion-btn-icon-width);
      transition: var(--bs-accordion-btn-icon-transition);
    }
  
    &:hover {
      z-index: 2;
    }
  
    &:focus {
      z-index: 3;
      border-color: var(--bs-accordion-btn-focus-border-color);
      outline: 0;
      box-shadow: var(--bs-accordion-btn-focus-box-shadow);
    }
  }
  
  .accordion-header {
    margin-bottom: 0;
  }
  
  .accordion-item {
    color: var(--bs-accordion-color);
    background-color: var(--bs-accordion-bg);
    border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
  
    &:first-of-type {
      border-top-radius: var(--bs-accordion-border-radius);
  
      .accordion-button {
        border-top-radius: var(--bs-accordion-inner-border-radius);
      }
    }
  
    &:not(:first-of-type) {
      border-top: 0;
    }
  
    // Only set a border-radius on the last item if the accordion is collapsed
    &:last-of-type {
      border-bottom-radius: var(--bs-accordion-border-radius);
  
      .accordion-button {
        &.collapsed {
          border-bottom-radius: var(--bs-accordion-inner-border-radius);
        }
      }
  
      .accordion-collapse {
         border-bottom-radius: var(--bs-accordion-border-radius);
      }
    }
  }
  
  .accordion-body {
    padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
  }
  
  
  // Flush accordion items
  //
  // Remove borders and border-radius to keep accordion items edge-to-edge.
  
  .accordion-flush {
    .accordion-collapse {
      border-width: 0;
    }
  
    .accordion-item {
      border-right: 0;
      border-left: 0;
      border-radius: 0;
  
      &:first-child { border-top: 0; }
      &:last-child { border-bottom: 0; }
  
      .accordion-button {
        &,
        &.collapsed {
          border-radius: 0;
        }
      }
    }
  }
  